<template>
  <div>
    <hr>
    <b-row class="mt-1">
      <b-col cols="12">
        <b-card-actions
            class="cari-liste-card"
        >
          <form class="mb-2"
                @submit.prevent="priceUpdate"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Tarihe Göre Özel Fiyat Tarifesi</h4>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      value="Submit"
                      variant="bgInfo"
                      class="border-0 float-right"
                      :style="{backgroundColor:$store.getters.cardTitleButton}"
                  >
                    Ayarları Uygula
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgDanger"
                      class="myButton text-white border-0 float-right mr-1"
                      to="/odalar"
                      :style="{backgroundColor:$store.getters.cardTitleButton}"
                  >
                    <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                    />
                    Oda kayıtları
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <hr>
            <b-row class="justify-content-start">
              <b-col lg="4" md="4" sm="12" cols="12">
                <b-form-group
                    label="Durum"
                    label-for="basicInput"
                    class="mt-1"
                >
                  <v-select
                      v-model="durum"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="durumOption"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12" cols="12">
                <b-form-group
                    label="Ücretlendirma Başlangıç Tarihi"
                    label-for="basicInput"
                    class="mt-1"
                >
                  <flat-pickr
                      v-model="baslangic"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12" cols="12">
                <b-form-group
                    label="Ücretlendirme Bitiş Tarihi"
                    label-for="basicInput"
                    class="mt-1"
                >
                  <flat-pickr
                      v-model="bitis"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  v-for="tarife in tarifeListesi"
                  :key="tarife.tarifeID + 10"
                  lg="4" md="4" sm="6" cols="6"
              >
                <b-form-group
                    :label="tarife.tarifeAdi + ' Tarifesi'"
                    label-for="basicInput"
                    class="mt-1 fw-bold"
                >
                  <b-form-input
                      :value="returnUcret(tarife.tarifeID)"
                      :name="tarife.tarifeID + '-ucret'"
                      step="0.0001"
                      type="number"
                      v-numericOnly

                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <b-row class="justify-content-start d-flex">
              <b-col
                  v-for="tarife in tarifeListesi"
                  :key="tarife.tarifeID + 20"
                  lg="4" md="4" sm="6" cols="6"
              >
                <b-form-group
                    :label="tarife.tarifeAdi + ' Oda Kapatma'"
                    label-for="basicInput"
                    class="mt-1 fw-bold"
                >
                  <b-form-input
                      :value="returnOdaKapatma(tarife.tarifeID)"
                      :name="tarife.tarifeID + '-odaKapatma'"
                      step="0.0001"
                      type="number"
                      v-numericOnly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <p class="aciklamaText text-start">
                  * Oda Kapatmada belirtilen fiyatlar, diğer yatakların
                  <strong style="text-decoration: underline">yatak başı</strong>
                  ücretidir. <strong>Toplam tutarı girmeyiniz!.</strong>
                </p>
              </b-col>
              <b-col
                  v-for="tarife in tarifeListesi"
                  :key="tarife.tarifeID + 30"
                  lg="4" md="4" sm="6" cols="6"
              >
                <b-form-group
                    :label="tarife.tarifeAdi + ' Evli Çift Toplam Tutar'"
                    label-for="basicInput"
                    class="mt-1 fw-bold"
                >
                  <b-form-input
                      :value="returnCift(tarife.tarifeID)"
                      :name="tarife.tarifeID + '-ciftTarife'"
                      step="0.0001"
                      type="number"
                      v-numericOnly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <p class="aciklamaText text-success text-start">
                  *Odada evli çiftler için duble yatak mevcutsa konukların
                  <strong>* toplam konaklama ücretini giriniz.</strong> Eğer
                  odada duble yatak bulunmuyorsa Evli Çift Rezervasyonu
                  seçeneğini <strong>"Hayır"</strong> olarak seçiniz.
                </p>
                <hr>
              </b-col>
              <b-col
                  v-for="tarife in tarifeListesi"
                  :key="tarife.tarifeID + 40"
                  lg="4" md="4" sm="6" cols="6"
              >
                <b-form-group
                    :label="tarife.tarifeAdi + ' Diğer Yatak Farklı Ücret'"
                    label-for="basicInput"
                    class="mt-1 fw-bold"
                >
                  <b-form-input
                      :value="returnDigerYatak(tarife.tarifeID)"
                      :name="tarife.tarifeID + '-digerYatak'"
                      step="0.0001"
                      type="number"
                      v-numericOnly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <p class="aciklamaText text-success text-start">
                  *Odaya eklenen diğer yetişkinler için farklı bir ücretlendirme
                  kullanmak istiyorsanız lütfen yukarıdaki alanları doldurunuz.
                  Standart tarifeler için
                  <strong>"Standart Tarife Kullan"</strong> seçeneğini seçiniz.
                </p>
                <hr>
              </b-col>
              <b-col
                  v-for="tarife in tarifeListesi"
                  :key="tarife.tarifeID + 50"
                  lg="4" md="4" sm="6" cols="6"
              >
                <b-form-group
                    :label="tarife.tarifeAdi + ' Ek Yatak'"
                    label-for="basicInput"
                    class="mt-1 fw-bold"
                >
                  <b-form-input
                      :value="returnEkYatak(tarife.tarifeID)"
                      :name="tarife.tarifeID + '-ekYatak'"
                      step="0.0001"
                      type="number"
                      v-numericOnly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <p class="aciklamaText text-success text-start">
                  *Ek yatak, özel durumlarda ve ek yatak imkanı olan odalar için
                  aktif olmalıdır.
                </p>
                <hr>
              </b-col>
              <b-col
                  v-for="tarife in tarifeListesi"
                  :key="tarife.tarifeID + 60"
                  lg="4" md="4" sm="6" cols="6"
              >
                <b-form-group
                    :label="tarife.tarifeAdi + ' Ücretli Çocuk'"
                    label-for="basicInput"
                    class="mt-1 fw-bold"
                >
                  <b-form-input
                      :value="returnCocuk(tarife.tarifeID)"
                      :name="tarife.tarifeID + '-ucretliCocuk'"
                      step="0.0001"
                      type="number"
                      v-numericOnly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <p class="aciklamaText text-success text-start">
                  *Standart olarak yetişkin konuğun tarifesinin %50 tutarının
                  hesaplanması için Ücretli Çocuk Tarifesi seçeneğini
                  <strong>%50 indirimli</strong> olarak seçiniz. Farklı bir
                  tarife kullanılacak ise
                  <strong>"Özel Fiyat Uygula"</strong> seçimini yapıp ilgili
                  alanları doldurunuz.
                </p>
                <hr>
              </b-col>
            </b-row>
          </form>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr

flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  components: {
    BCardActions,
    flatPickr,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      meSayac: 0,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      baslangic: null,
      bitis: null,
      odaID: this.$route.params.id,
      odaAdi: '',
      tarifeListesi: [],
      odaTarifeDetay: [],
      durum: {
        title: 'Pasif',
        key: 0
      },
      durumOption: [
        {
          title: 'Pasif',
          key: 0
        },
        {
          title: 'Aktif',
          key: 1
        },
      ],
    }
  },
  created() {
    if (this.odaID) {
      this.$store
          .dispatch('odaOzelFiyat', { id: this.odaID })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.odaAdi = res.odaBilgileri.odaAdi
            this.tarifeListesi = res.tarifeListesi
            this.odaTarifeDetay = res.odaTarifeDetay
            this.baslangic = res.edit.baslangic != '0000-00-00' ? res.edit.baslangic : null
            this.bitis = res.edit.bitis != '0000-00-00' ? res.edit.bitis : null
            this.durum = res.edit.durum == 1
                ? {
                  title: 'Aktif',
                  key: 1
                }
                : {
                  title: 'Pasif',
                  key: 0
                }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    }
  },
  beforeDestroy() {
    this.$store.commit('clearOdaOzelFiyat')
  },
  methods: {
    priceUpdate(e) {
      const fd = new FormData()
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'odaOzelFiyat')
      fd.append('odaID', this.odaID)
      fd.append('durum', this.durum.key)
      fd.append('baslangic', this.baslangic)
      fd.append('bitis', this.bitis)
      fd.append('guncelle', true)
      Object.values(this.$store.getters.tarife)
          .forEach(tarife => {
            Object.values(e.target.elements[`${tarife.tarifeID}-ucret`])
                .forEach(
                    element => {
                      if (this.meSayac == 1) {
                        fd.append([`${tarife.tarifeID}-ucret`], element || 0)
                      }
                      this.meSayac += 1
                    },
                )
            this.meSayac = 0
            Object.values(
                e.target.elements[`${tarife.tarifeID}-odaKapatma`],
            )
                .forEach(element => {
                  if (this.meSayac == 1) {
                    fd.append([`${tarife.tarifeID}-odaKapatma`], element || 0)
                  }
                  this.meSayac += 1
                })
            this.meSayac = 0
            Object.values(
                e.target.elements[`${tarife.tarifeID}-ciftTarife`],
            )
                .forEach(element => {
                  if (this.meSayac == 1) {
                    fd.append([`${tarife.tarifeID}-ciftTarife`], element || 0)
                  }
                  this.meSayac += 1
                })
            this.meSayac = 0
            Object.values(
                e.target.elements[`${tarife.tarifeID}-digerYatak`],
            )
                .forEach(element => {
                  if (this.meSayac == 1) {
                    fd.append([`${tarife.tarifeID}-digerYatak`], element || 0)
                  }
                  this.meSayac += 1
                })
            this.meSayac = 0
            Object.values(e.target.elements[`${tarife.tarifeID}-ekYatak`])
                .forEach(
                    element => {
                      if (this.meSayac == 1) {
                        fd.append([`${tarife.tarifeID}-ekYatak`], element || 0)
                      }
                      this.meSayac += 1
                    },
                )
            this.meSayac = 0
            Object.values(
                e.target.elements[`${tarife.tarifeID}-ucretliCocuk`],
            )
                .forEach(element => {
                  if (this.meSayac == 1) {
                    fd.append([`${tarife.tarifeID}-ucretliCocuk`], element || 0)
                  }
                  this.meSayac += 1
                })
            this.meSayac = 0
          })
      axios
          .post(store.state.POST_URL, fd)
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res.data)
            this.$router.push('/odalar')
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            this.value = 0
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  tet: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    returnUcret(id) {
      return this.odaTarifeDetay[id].ucret
    },
    returnOdaKapatma(id) {
      return this.odaTarifeDetay[id].odaKapatma
    },
    returnCift(id) {
      return this.odaTarifeDetay[id].ciftTarife
    },
    returnDigerYatak(id) {
      return this.odaTarifeDetay[id].digerYatak
    },
    returnEkYatak(id) {
      return this.odaTarifeDetay[id].ekYatak
    },
    returnCocuk(id) {
      return this.odaTarifeDetay[id].ucretliCocuk
    },
  },
}
</script>
<style scoped>
.fw-bold {
  font-weight: 900 !important;
}

.progress {
  height: 0.557rem !important;
}

.my-down-button {
  margin: 0 auto;
  display: flex;
}

.opacity {
  opacity: 0.4 !important;
}

.row[data-v-61f55772] {
  justify-content: start;
}

.quill-editor {
  height: 305px;
}

.line {
  height: 2px;
  background-color: #ebe9f1;
  width: 100%;
}

.my-top-row {
  justify-content: space-between;
  display: flex;
}

@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td[data-v-199ebc45][data-v-199ebc45] {
  text-align: center;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

[dir] .table th[data-v-199ebc45],
[dir] .table td[data-v-199ebc45] {
  padding: 0.1rem;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #31526f;
  color: white;
  padding: 0.6rem !important;
  border: none;
  text-align: center;
}

[dir] .table th[data-v-4ab16a2a],
[dir] .table td {
  padding: 0.2rem !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-body {
  padding: 10px 30px;
}

.aciklamaText {
  color: #28c76f !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: "Dosya" !important;
}
</style>
